import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import Layout from './Components/Layout/Layout';
// import Home from './Pages/Home/Home';
// import Book from './Pages/Book/Book';
// import Services from './Pages/Services/Services';
import LandingPage from './Pages/LandingPage/LandingPage';

function App() {
  return (
    <>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            {/* <Route path="/" element={<Home />} />
            <Route path='book' element={<Book />}></Route>
            <Route path='services' element={<Services />}></Route> */}

          </Routes>
        </Layout>
      </Router>
    </>
  );
}

export default App;
