import React, { useState } from 'react';
import './Layout.scss';
import Navbar from './Navbar/Navbar';
import Footer from '../Footer/Footer';
import MobileMenu from './MobileMenu/MobileMenu';

const Layout = ({ children }) => {

    const [mobMenu, toggleMobMenu] = useState(false);

    function toggleMenu() {
        toggleMobMenu(!mobMenu);
    }

    return (
        <>
            {/* <MobileMenu mobMenu={mobMenu} toggleMenu={toggleMenu} />
            <Navbar toggleMenu={toggleMenu} mobMenu={mobMenu} /> */}
            <main className="container-fluid content main">
                {children}
            </main>
            <Footer />
        </>
    );
};

export default Layout;