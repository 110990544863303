import React from 'react';
import './LandingPage.scss';
import Logo from '../../Assets/Images/Lucky13FullLogo.png';
import LineIcon from 'react-lineicons';
import ContactForm from '../../Components/ContactForm/ContactForm';

const LandingPage = () => {

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        const offset = 78; // Height of the navbar
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    const services = [
        {
            name: "Exterior Wash & Wax",
            description: "Thorough hand wash and high-quality wax to protect and shine your vehicle’s paintwork.",
            price: 50.00
        },
        {
            name: "Paint Correction",
            description: "Removing imperfections such as swirl marks, scratches, and oxidation.",
            price: 150.00
        },
        {
            name: "Ceramic Coating",
            description: "Application of a durable, long-lasting ceramic coating for superior protection.",
            price: 400.00 // Discounted from £500.00
        },
        {
            name: "Full Interior Valet",
            description: "Basic interior cleaning including vacuuming, dusting, and wipe-down of all surfaces.",
            price: 60.00
        },
        {
            name: "Full Interior Detail",
            description: "Comprehensive deep cleaning of all interior surfaces.",
            price: 120.00 // Discounted from £150.00
        },
        {
            name: "Full Exterior Detail",
            description: "Extensive cleaning and protection of the exterior, including wash, wax, and paint correction.",
            price: 160.00 // Discounted from £200.00
        },
        {
            name: "Full Inside/Out Detail including Engine Bay",
            description: "Complete detailing of both interior and exterior, plus engine bay cleaning.",
            price: 280.00 // Discounted from £350.00
        },
        {
            name: "Premium Package",
            description: "Full Interior Valet, Full Exterior Detail, and Wheel & Tire Detailing.",
            price: 280.00 // Not discounted
        },
        {
            name: "Ultimate Detail Package",
            description: "Full Interior Detail, Full Exterior Detail, and Ceramic Coating.",
            price: 560.00 // Discounted from £700.00
        },
        {
            name: "Complete Showroom Package",
            description: "Full Inside/Out Detail including Engine Bay, Paint Correction, and Ceramic Coating.",
            price: 680.00 // Discounted from £850.00
        }
    ];

    return (
        <>
            <div className="hero-section">
                <div className="hero-content fadeIn">
                    <img src={Logo} alt="Lucky13Detailing" className="img-fluid" />
                    <span className='flashing-text' onClick={() => { scrollToSection("about") }}><LineIcon name="chevron-down-circle" alt="We start by understanding your needs and goals." /></span>
                </div>
            </div>
            <div className="landing-page">

                <section className="business-details py-5 text-center text-white">
                    <div className="container">
                        <h2 className="mb-5">About Us</h2>
                        <p className="lead mb-5">
                            Lucky 13 Detailing is a premium mobile car detailing service dedicated to serving the North East of England, including Durham, Middlesbrough, Stockton, Darlington, Newcastle, and surrounding areas. Our goal is to provide exceptional car care at your convenience.
                        </p>
                        <h2 className="text-center mb-5">Our Premium Car Detailing Services</h2>
                        <p className="text-center mb-5 lead">
                            At Lucky 13 Detailing, exceptional attention to detail is at the heart of everything we do. Each service is performed with the utmost precision, ensuring your vehicle is restored to its best possible condition. Understanding that your car is more than just transportation—it's an investment—drives the commitment to deliver superior care and results.
                        </p>
                        <p className="text-center mb-5 lead">
                            From the moment work begins on your vehicle, every step is taken with careful consideration. Each service includes thorough cleaning and restoration, both inside and out. Whether it's hand washing and waxing the exterior or deep cleaning and conditioning the interior, no detail is overlooked. Every surface, nook, and cranny is attended to, guaranteeing a spotless and polished finish.
                        </p>
                        <p className="text-center mb-5 lead">
                            Exceptional results require exceptional products. Only the highest quality tools, chemicals, and products in the industry are used. This selection of premium products includes top-of-the-line waxes, polishes, and coatings designed to enhance the appearance of your car while providing long-lasting protection. The cleaning agents used are gentle yet effective, ensuring your vehicle's surfaces are treated with the utmost care.
                        </p>
                        <p className="text-center mb-5 lead">
                            Experience the difference with Lucky 13 Detailing. Maintain the beauty and value of your vehicle with premium car detailing services. Contact today to schedule an appointment or to learn more about the offerings.
                        </p>
                    </div>
                </section>

                <section className="services py-5 text-center">
                    <div className="container">
                        <h2>Our Services</h2>
                        <div className="row">
                            {services.map((service, index) => (
                                <div className="col-md-6 mb-4" key={index}>
                                    <div className="service-box p-4 h-100 d-flex flex-column justify-content-between">
                                        <div>
                                            <h3 className="service-name mb-3">{service.name}</h3>
                                            <p className="service-description">{service.description}</p>
                                        </div>
                                        <div>
                                            <p className="service-price mt-3"><strong>From £{service.price.toFixed(2)}</strong></p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {/* <ContactForm /> */}
            </div>
        </>
    );
};

export default LandingPage;